<template>
    <div class="page1">
        <ETable :tableTitle="tableTitle"
                :tableData="tableData"
                :needPagination="false"
        >
            <el-table-column
                    fixed="right"
                    label="操作"
                    width="180px"
            >
                <template slot-scope="scope">
                    <div>
                        <EButton type="text" @click="showDialog(scope.row.a_id,'1')" :disabled="scope.row.status == 1">
                            修改
                        </EButton>
                        <EButton type="text" @click="showDialog(scope.row.a_id,'1')" :disabled="scope.row.status == 1">
                            删除
                        </EButton>
                    </div>
                </template>
            </el-table-column>
        </ETable>
        <EDialog :dialogVisible="dialogVisible" title="添加" @handleClose="dialogVisible=false"
                 width="30%"
        >
            <EForm
                    :formColumns="dialogFormColumns"
                    :rowSize="1"
                    :optionsBtn="false"
                    :actionBtn="true"
                    :formData="dialogForm"
                    ref="form"
                    @onSearch="onSearch"
                    :searchFlag="false"
                    @handleClick="saveData"
                    @handleClose="cancelDialog"
                    :labelPosition="labelPosition"
                    :labelWidth="labelWidth"
                    :formRules="formRules"
            >
            </EForm>
        </EDialog>
    </div>
</template>

<script>
  import ETable from '@/components/ETable'
  import Http from "@/service/http";
  import EDialog from '@/components/EDialog'
  import EButton from '@/components/EButton'
  import EForm from '@/components/EForm'
  import {identity_type, status} from '@/assets/js/config'
  import vxRule from "@/assets/js/formValidate";
  export default {
    name: 'Login',
    data() {
      return {
        tableTitle: [
          {
            label: '费用名称',
            prop: 'warmPriceName'
          },
          {
            label: '单价（每平米）',
            prop: 'warmPrice'
          },
          {
            label: '新建人',
            prop: 'createUser'
          },
          {
            label: '新建时间',
            prop: 'createTime',
          },
          {
            label: '最近一次修改人',
            prop: 'updateUser'
          },
          {
            label: '修改时间',
            prop: 'updateTime'
          }
        ],
        tableData: [
        ],
        searchForm: {
          id:'',
          area_code: '',
          title: '',
          content: '',
          user_type:'',
          status:'',
          page: 1,
          page_size: 10,
        },
        count: null,
        dialogFormColumns: [
          {
            title: '位置名称',
            type: 'text',
            property: 'area_code',
            placeHolder: '最多可录入20字',
            show: true,
          },
          {
            type:'actionBtn',
            show:true
          }
        ],
        exportData:{},
        dialogVisible:false,
        labelPosition:'right',
        labelWidth:'100px',
        formRules:{
          area_code: vxRule(true, '',"change", "推送区域不能为空"),
          title: [vxRule(true, '',"blur", "标题不能为空"),
            vxRule(true, val=>{
              return {result: val.length<=20, errMsg: "最多可录入20字"}})
          ],
          user_type: vxRule(true, '',"change", "用户不能为空"),
          content: [vxRule(true, '',"blur", "内容不能为空"),
            vxRule(true, val=>{
              return {result: val.toString().length<=75, errMsg: "最多可录入75字"}})],
          position_id: [vxRule(true, '',"change", "跳转位置不能为空")],
          status: vxRule(true, '',"change", "用户不能为空"),
        },
        dialogForm:{
          area_code:'',
          id:'',
          title:'',
          content:'',
          user_type:'1',
          position_id:'',
          push_time:'',
          status:'0'
        },
        options:[]
      }
    },
    watch: {},
    created() {
      this.getData()
    },
    components: {ETable, EDialog, EButton, EForm},
    beforeMount() {

    },
    methods: {

      cancelDialog(){
        this.dialogVisible=false
        this.dialogForm={
          area_code:'',
          id:'',
          title:'',
          content:'',
          user_type:'1',
          position_id:'',
          push_time:'',
          status:'0'
        }
      },
      showDialog(){
        this.dialogVisible=true
      },
      saveData(){

      },
      async getData(){
        let res = await Http.getWarmPriceList()
        if(res.code == 200){
          console.log(res)
          this.tableData = res.data.records
        }
      }
    }
  }
</script>

<style lang="scss">

</style>
